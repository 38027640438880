#div1 {
	/*Certificado como um todo*/
	position: absolute;
	top: 0px;
	max-height: 595px;
	left: 0px;
}

#div2 {
	/*Corpo de todo o texto do certificado */
	width: 842px;
	max-height: 595px;
	height: 595px;
	position: absolute;
	background-color: #fff;
}

#div3 {
	/*Texto principal certificado*/
	max-height: 595px;
	width: 600px;
	top: 270px;
	left: 15%;
	position: absolute;
	text-align: center;
	font-family: 'Roboto';
	font-size: 25px;
	color: #33404f;
}

#div4 {
	/*Dados mauricio otavio + fundador geral*/

	height: 70px;
	width: 300px;
	top: 463px;
	left: 30%;
	position: absolute;
	text-align: center;
	font-family: 'Lato';
	font-size: 35px;
	color: #33404f;
	background: #fff;
}

#idImg {
	/*Logo capacitaMPE*/
	width: 256px;
	max-height: 595px;
	position: absolute;
	top: 20px;
	left: 35%;
}

#idImg2 {
	/*Moldura certificado*/
	width: 842px;
	max-height: 595px;
	height: 595px;
	position: absolute;
}

#Text {
	/*Texto: Certificado de conclusão*/
	top: 140px;
	max-height: 595px;
	left: 23%;
	position: absolute;
	font-family: 'Lobster';
	text-align: center;
	font-size: 50px;
	color: #33404f;
}

#Text2 {
	/*Dados mauricio otavio + fundador geral*/
	position: relative;
	top: -25px;
	font-size: 10px;
	color: #33404f;
	font-family: 'Lato';
}

#Text3 {
	/*Autenticacao + url*/
	position: relative;
	top: 540px;
	left: 50%;
	color: #33403f;
	font-family: 'Lato';
	font-size: 12px;
}

#Assinatura {
	width: 80px;
	max-height: 595px;
	height: 80px;
	position: absolute;
	top: 403px;
	left: 42%;
}

#QRcode {
	height: 100px;
	width: 100px;
	top: 425px;
	left: 73%;
	position: absolute;
}