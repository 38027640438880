/*Check*/
.label-check{
	color: #F5A623;
    font-size: 125%;
    font-family: "Gotham Rounded Medium";
    margin-bottom: 20%;
}

.label-check-quiz{
	color: #F5A623;
    font-size: 135%;
    font-family: "Gotham Rounded Medium";
}

.check-quiz, .check-quiz{
	visibility: hidden;
}

.check-quiz + .label-text:before{
	content: "\f096";
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing:antialiased;
	width: 1em;
	display: inline-block;
	margin-right: 5px;
}

.check-quiz:checked + .label-text:before{
	content: "\f14a";
	color: #4A90E2;
}

.check-quiz:disabled + .label-text{
	color: #aaa;
}

.check-quiz:disabled + .label-text:before{
	content: "\f0c8";
	color: #ccc;
}

.check-quiz + .label-text:before{
    content: "\f10c";
    color: #868787;
	font-family: "FontAwesome";
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing:antialiased;
	width: 1em;
	display: inline-block;
	margin-right: 5px;
}

.check-quiz:checked + .label-text:before{
	content: "\f192";
	color: #3970B0;
}

.check-quiz:disabled + .label-text{
	color: #aaa;
}

.check-quiz:disabled + .label-text:before{
	content: "\f111";
	color: #ccc;
}
/*Check*/