@font-face {
    font-family: 'Gotham Rounded Bold';
    src: url('deprecated/components/Fonts/GOTHAMROUNDEDBOLD.TTF');
}

@font-face {
    font-family: 'Gotham Rounded Book';
    src: url('deprecated/components/Fonts/GOTHAMROUNDEDBOOK.TTF');
}

@font-face {
    font-family: 'Gotham Rounded Light';
    src: url('deprecated/components/Fonts/GOTHAMROUNDEDLIGHT.TTF');
}

@font-face {
    font-family: 'Gotham Rounded Medium';
    src: url('deprecated/components/Fonts/GOTHAMROUNDEDMEDIUM.TTF');
}

body,
html {
    height: 100vh;
    background-color: #F8F8F8;
    overflow-x: hidden;
}

body {
    font-family: 'Shrikhand', cursive;
}

.modal-open .modal {
    background: rgba(255, 255, 255, 0.5) !important;
}

#password-modal {
    background: rgba(255, 255, 255, 0.5) !important;
}

.image {
    width: 100%;
}

.image-back {
    width: 80%;
}

.image-reduced {
    width: 90%;
}

.pdf-iframe {
    width: 100%;
    height: 500px;
}

.div-progress {
    display: none;
}

#toggleSms {
    display: none;
}

#smsClose {
    display: none;
}

#rating-hidden {
    display: none;
}

.rating-video {
    height: 100% !important;
    padding-bottom: 5% !important;
    background-color: #474747e0;
}

.rating-video-text {
    font-family: "Gotham Rounded Medium";
    font-size: 15pt;
    color: #ffffff;
}

.rating-video-pessimo {
    font-family: "Gotham Rounded Book";
    font-size: 7pt;
    color: #DDDDDD;
}

.rating-video-excelente {
    font-family: "Gotham Rounded Book";
    font-size: 7pt;
    margin-left: 45%;
    color: #DDDDDD;
}

.pdf {
    display: none;
}

.margins {
    margin-top: 39px;
    float: right;
    margin-right: 44px;
}

.pdf-text {
    text-align: center;
}

.guia-de-referencia,
.class-attachments {
    margin-top: 2%;
    margin-bottom: 3%;
    border: solid 2px #057568;
    border-radius: 7px;
    background-color: #FFFFFF;
    height: 70px;
    text-align: center;
    color: #057568;
    font-family: "Gotham Rounded Medium";
    font-size: 23pt;
}

.guia-de-referencia-min {
    border: solid 2px #057568;
    border-radius: 7px;
    background-color: #057568;
    height: 55px;
    text-align: center;
    color: #FFFFFF;
    font-family: "Gotham Rounded Medium";
    font-size: 9pt;
}

.assistir-aula-min {
    border: solid 2px #17549B;
    border-radius: 7px;
    background-color: #FFFFFF;
    height: 55px;
    text-align: center;
    color: #17549B;
    font-family: "Gotham Rounded Medium";
    font-size: 9pt;
}

.loading-img {
    width: 70vh;
}

.text-loading {
    text-align: center;
    color: #17549B;
    font-family: "Gotham Rounded Medium";
    font-size: 20pt;
}

#img-assistir {
    height: 55px;
}

.classImg {
    border-radius: 7px;
    width: 90%;
}

.answer {
    margin-top: 5%;
    border: solid 2px #ffffff;
    border-radius: 7px;
    background-color: rgba(255, 255, 255, 0);
    height: 70px;
    text-align: center;
    color: #ffffff;
    font-family: "Gotham Rounded Book";
    font-size: 15pt;
}

@media (max-width: 768px) {
    .loading-img {
        max-width: 100% !important;
    }
    .answer {
        font-size: 10pt;
    }
    .guia-de-referencia {
        font-size: 15pt !important;
    }
    .mobile-div {
        margin-top: 5% !important;
    }
    .mobile-div-10 {
        margin-top: 10% !important;
    }
    .lg-div {
        display: none;
    }
    .resposive-title-dashboard {
        font-size: 26pt !important;
    }
    .responsive-title {
        font-size: 20pt !important;
        text-align: center !important;
    }
    .margins {
        margin-top: 24px !important;
        float: right !important;
        margin-right: 10px !important;
    }
}

.resposive-title-dashboard {
    font-family: "Gotham Rounded Light";
    font-size: 70pt;
    color: #505050;
    margin-bottom: -1.2%;
}

.responsive-title {
    font-family: "Gotham Rounded Book";
    font-size: 30pt;
    color: #17549B;
}

.rating-text {
    font-family: "Gotham Rounded Medium";
    font-size: 10pt;
    color: #424242;
}

.title-min {
    font-family: "Gotham Rounded Medium";
    font-size: 17pt;
    color: #000;
}

.title-min-shop {
    font-family: "Gotham Rounded Medium";
    font-size: 8pt;
    color: #000;
}

.lista-aulas-text {
    font-family: "Gotham Rounded Medium";
    font-size: 16px;
}

.title-min-shop {
    font-family: "Gotham Rounded Medium";
    font-size: 8pt;
    color: #000;
}

.border-1 {
    background-color: rgb(209, 207, 207);
    border: 1px solid rgb(209, 207, 207);
    border-radius: 13px;
    color: white;
    margin-right: 5px;
}

.form-search {
    border: none;
    height: 40px;
    font-size: 18px;
    background: transparent;
    font-family: 'Gotham Rounded Medium', sans-serif;
    width: 100%;
    border-bottom: 1px solid #B7B8B8 !important;
}

.form-search:focus {
    outline-color: transparent !important;
    border-bottom: 2px solid #B7B8B8 !important;
}

#lupa {}

#lupa:after {
    font-family: FontAwesome;
    color: #B7B8B8;
    font-size: 16px;
    content: "\f002"
}

.margin-text-oops {
    margin-top: 34px;
    margin-left: 10px
}

#frown {
    float: left;
    margin: 10px 0px 0px -20px;
}

#frown:after {
    font-family: FontAwesome;
    color: #B7B8B8;
    font-size: 50px;
    content: "\f119"
}

.title-video-min {
    font-family: "Gotham Rounded Medium";
    font-size: 12pt;
    color: #000;
}

.title-modal-min {
    font-family: "Gotham Rounded Medium";
    font-size: 13pt;
    color: #000;
}

.modal-icon {
    font-size: 17px;
}

.modal-color-icon {
    color: #17549B;
}

.guia-text {
    margin-top: 3%;
    font-family: "Gotham Rounded Book";
    font-size: 11pt;
    color: #000;
}

.modal-text-icon {
    font-family: "Gotham Rounded Light";
    font-size: 11pt;
}

.text-min {
    font-family: "Gotham Rounded Light";
    font-size: 13pt;
    color: #000;
}

.subtitle-min {
    font-family: "Gotham Rounded Book";
    font-size: 11pt;
    color: #000;
}

.subtitle-link-min {
    font-family: "Gotham Rounded Book";
    font-size: 11pt;
    text-decoration: underline;
    color: #000;
}

.img-min-circle {
    height: 110px;
    width: 110px;
}

.icon-middle {
    margin-top: 20px;
}

.icon-medium {
    font-size: 30px;
}

.icon-pdf {
    font-size: 16px;
}

.video-tutorial {
    border-style: solid;
    border-color: #4A90E2;
}

.placeholder {
    border: 1px solid #979797;
    border-radius: 7px;
    font-family: "Gotham Rounded Book";
    font-size: 20px;
    height: 60px;
}

.main-header {
    padding: 0 !important;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, .2) !important;
}


/* Steps */

.step {
    position: relative;
    min-height: 1em;
}

.step+.step {
    margin-top: 1.5em
}

.step>div:first-child {
    position: static;
    height: 0;
}

.step>div:not(:first-child) {
    margin-left: 1.5em;
    padding-left: 1em;
}


/* Circle */

.circle-step {
    background: white;
    position: relative;
    width: 1.3em;
    height: 1.3em;
    line-height: 1.5em;
    border-radius: 100%;
    border: 1px solid #17549B;
}


/* Vertical Line */

.circle-step::after {
    content: ' ';
    position: absolute;
    display: block;
    top: 1px;
    left: 50%;
    height: 100%;
    width: 2px;
    transform: scale(1, 2);
    transform-origin: 50% -100%;
    background-color: #17549B;
}

.step:last-child .circle-step:after {
    display: none;
}


/* Stepper Titles */

.title-step {
    color: #4A90E2;
    font-family: "Gotham Rounded Medium";
    font-size: 13pt;
}

.step-duration {
    color: #979797;
    font-family: "Gotham Rounded Medium";
    font-size: 13pt;
}

.step-div {
    margin-top: 1%;
}


/* Steps */

.lessons-viewd-card {
    padding-top: 25px;
    padding-bottom: 35px;
}

.lessons-viewd-card:nth-child(odd) {
    background-color: #EAF5FD;
}

.lessons-viewd-card:last-child {
    margin-bottom: 3%;
}

.arrow-toggle {
    transition: all 1s ease-in-out;
}

.arrow-toggle:after {
    content: "\e114";
    margin-left: 10px;
    font-size: 20px;
}

.arrow-toggle.collapsed:after {
    content: "\e080";
}

.clickable {
    cursor: pointer;
}

.clickablePurchase:hover {
    color: white;
}

.icon-clickable {
    cursor: pointer;
    font-size: 28px;
    color: gray;
}

.icon-clickable:hover {
    color: #3970B0;
}

.footer {
    bottom: 0;
    width: 100%;
    background-color: #4A90E2;
    color: white;
    text-align: left;
    font-family: "Gotham Rounded Light";
    font-size: 12pt;
    padding: 1%;
}

.login-label {
    color: #3970B0;
    font-family: "Gotham Rounded Medium";
    font-size: 16px;
}

.code-text {
    color: #3970B0;
    font-family: "Gotham Rounded Medium";
    font-size: 13px;
}

.pad-flags {
    text-align: center;
    margin-bottom: 20px;
}

.pad-flags img {
    max-height: 30px;
    margin: 0 3px;
}

.label-profile-card {
    margin-bottom: 0px;
    font-size: 18px;
}

.register-label {
    color: #3970B0;
    font-family: "Gotham Rounded Medium";
    font-size: 18px;
}

.concluida {
    color: #3970B0;
    font-family: "Gotham Rounded Medium";
    font-size: 12px;
}

.quiz-label {
    color: #3970B0;
    font-family: "Gotham Rounded Medium";
    font-size: 20px;
}

.quiz-resposta {
    color: #908E8E;
    font-family: "Gotham Rounded Medium";
    font-size: 18px;
}

.register-placeholder {
    font-family: "Gotham Rounded Light";
    font-size: 18px;
    height: 40px;
    background-color: #F8FAFB;
    border: 1px solid #979797;
}

.lessons-placeholder {
    font-family: "Gotham Rounded Book";
    font-size: 16px;
    color: #979797;
    height: 55px;
    background-color: #F8FAFB;
    border: 1px solid #000;
    border-radius: 7px;
}

.cursor-pointer {
    cursor: pointer !important;
}

.card {
    max-width: 350px !important;
    border-radius: 7px !important;
    box-shadow: 1px 3px 4px rgba(0, 0, 0, .3) !important;
}

.card-radius-additional-lesson {
    -webkit-border-radius: 7px !important;
    -webkit-border-top-left-radius: 34px !important;
    -webkit-border-top-right-radius: 34px !important;
    -moz-border-radius: 7px !important;
    -moz-border-radius-topleft: 34px !important;
    -moz-border-radius-topright: 34px !important;
    border-radius: 7px !important;
    border-top-left-radius: 36px !important;
    border-top-right-radius: 36px !important;
}

.card-addtional-lessons {
    height: 200px !important;
    background-size: contain !important;
}

.card-title {
    font-family: "Gotham Rounded Medium";
    font-size: 19.2px;
    color: #000;
    height: 80px;
}

card-title-shop {
    font-family: "Gotham Rounded Medium";
    font-size: 15px;
    color: #000;
    height: 80px;
}

.card-views {
    font-family: "Gotham Rounded Medium";
    font-size: 20px;
    color: #979797;
}

.card-div {
    margin-top: 5%;
}

#register-form {
    margin-left: 5%;
}

#password-recovery {
    font-family: "Gotham Rounded Book";
    font-size: 140%;
    color: #4A90E2;
    margin-bottom: 45px;
}

#btn-entrar {
    font-family: "Gotham Rounded Book";
    font-size: 200%;
    color: white;
    background-color: #3DD768;
    width: 65%;
    height: 70px;
    margin-bottom: 45px;
}

#btn-enviar {
    font-family: "Gotham Rounded Book";
    font-size: 200%;
    color: white;
    background-color: #3DD768;
    width: 65%;
    height: 70px;
    margin-bottom: 45px;
}

#btn-continuar {
    float: right;
    font-family: "Gotham Rounded Book";
    font-size: 135%;
    color: #4A90E2;
    border-color: #4A90E2;
    border-width: 2px;
    border-radius: 8px;
    height: 70px;
}

#btn-assistir-novamente {
    font-family: "Gotham Rounded Book";
    font-size: 135%;
    background-color: #F8FAFB;
    color: #8E8F90;
    border-color: #8E8F90;
    border-width: 2px;
    border-radius: 8px;
    height: 70px;
    margin-bottom: 15px;
}

#btn-cancelar {
    font-family: "Gotham Rounded Book";
    font-size: 135%;
    background-color: #F8FAFB;
    color: #B3B3B3;
    border-color: #B3B3B3;
    border-width: 2px;
    border-radius: 8px;
    height: 70px;
    margin-bottom: 15px;
}

#btn-tutorial {
    font-family: "Gotham Rounded Light";
    font-size: 140%;
    background-color: #F8FAFB;
    color: #848484;
    border-color: #848484;
    border-width: 1px;
    border-radius: 8px;
    height: 70px;
    margin-bottom: 15px;
}

#btn-certificado {
    float: right;
    font-family: "Gotham Rounded Light";
    font-size: 140%;
    background-color: #FFC42C;
    color: #000;
    border-color: #848484;
    border-width: 1px;
    border-radius: 8px;
    height: 70px;
    margin-bottom: 15px;
}

#btn-certificado2 {
    font-family: "Gotham Rounded Light";
    font-size: 140%;
    background-color: #F8FAFB;
    color: #0f9116;
    border-color: #848484;
    border-width: 1px;
    border-radius: 8px;
    height: 70px;
    margin-bottom: 15px;
}

#btn-saiba-mais {
    float: left;
    font-family: "Gotham Rounded Light";
    font-size: 140%;
    background-color: #F8FAFB;
    color: #848484;
    border-color: #848484;
    border-width: 1px;
    border-radius: 8px;
    height: 70px;
    margin-bottom: 15px;
}

#btn-salvar-dados {
    font-family: "Gotham Rounded Book";
    font-size: 135%;
    color: white;
    background-color: #3DD768;
    height: 70px;
}

.textRed {
    color: #F44336;
}

.imgRelated {
    max-height: 250px;
}

.notVisible {
    display: none;
}

.background-img {
    background: url(deprecated/components/Images/background.jpg) no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
}

.app {
    margin: 0px !important;
}

body {
    font-family: 'Gotham Rounded', sans-serif;
    background: #F8FAFB;
}

@media(min-width:576px) {
     ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px #fff;
        box-shadow: inset 0 0 6px #fff;
        border-radius: 10px;
        background-color: #F5F5F5;
    }
     ::-webkit-scrollbar {
        width: 6px;
        background-color: #F5F5F5;
    }
     ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px #010c28;
        box-shadow: inset 0 0 6px #010c28;
        background-color: #010c28;
    }
}

p {
    font-family: 'Gotham Rounded', sans-serif;
    font-size: 1.1em;
    font-weight: 300;
    line-height: 1.7em;
}

a,
a:hover,
a:focus {
    text-decoration: none;
    transition: all 0.3s;
}

.menu-link,
.menu-link:hover,
.menu-link:hover {
    color: #fff;
}

strong {
    color: #000;
}

label {
    font-weight: normal;
}

.navbar {
    padding: 15px 10px;
    background: #fff;
    border: none;
    border-radius: 0;
    margin-bottom: 40px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
    margin-left: -20px;
}

.tab-menu {
    width: 100%;
    background: #3271BB;
    color: white;
}

.navbar-btn {
    box-shadow: none;
    outline: none !important;
    border: none;
}

.line {
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #ddd;
    margin: 40px 0;
}

i,
span {
    display: inline-block;
}

#sidebar {
    min-width: 250px;
    max-width: 250px;
    background: #010c28;
    color: #fff;
    transition: all 0.3s;
}

#sidebar.active {
    min-width: 90px;
    max-width: 90px;
    text-align: center;
}

#sidebar.active .sidebar-header h3,
#sidebar.active .CTAs {
    display: none;
}

#sidebar.active .sidebar-header img.hidden-small,
#sidebar.active .CTAs {
    display: none;
}

#sidebar.active .sidebar-header strong {
    display: block;
}

#sidebar ul li a {
    text-align: left;
}

#sidebar.active ul li a {
    padding: 20px 10px;
    text-align: center;
    font-size: 0.85em;
}

#sidebar.active ul li a i {
    margin-right: 0;
    display: block;
    font-size: 1.8em;
    margin-bottom: 5px;
}

#sidebar.active ul ul a {
    padding: 10px !important;
}

#sidebar.active a[aria-expanded="false"]::before,
#sidebar.active a[aria-expanded="true"]::before {
    top: auto;
    bottom: 5px;
    right: 50%;
    -webkit-transform: translateX(50%);
    -ms-transform: translateX(50%);
    transform: translateX(50%);
}

#sidebar .sidebar-header {
    padding: 20px;
    background: #010c28;
}

#sidebar .sidebar-header strong {
    display: none;
    font-size: 1.8em;
}

#sidebar ul.components {
    padding: 20px 0;
}

#sidebar ul li a {
    padding: 25px 10px;
    font-size: 1.1em;
    display: block;
}

#sidebar ul li a:hover {
    color: #82100e;
    background: #fff;
}

#sidebar ul li a i {
    margin-right: 10px;
}

#sidebar ul li.active>a,
a[aria-expanded="true"] {
    color: #fff;
    background: #82100e;
    border-left: 5px solid #ffffff;
}

nav#sidebar.active li#logout_sidebar_button {
    min-width: 90px;
    max-width: 90px;
}

a[data-toggle="collapse"] {
    position: relative;
}

a[aria-expanded="false"]::before,
a[aria-expanded="true"]::before {
    content: '\e259';
    display: block;
    position: absolute;
    right: 20px;
    font-family: 'Glyphicons Halflings';
    font-size: 0.6em;
}

a[aria-expanded="true"]::before {
    content: '\e260';
}

ul ul a {
    font-size: 0.9em !important;
    padding-left: 30px !important;
    background: #82100e;
}

ul.CTAs {
    padding: 20px;
}

ul.CTAs a {
    text-align: center;
    font-size: 0.9em !important;
    display: block;
    border-radius: 5px;
    margin-bottom: 5px;
}

a.download {
    background: #fff;
    color: #7386D5;
}

a.article,
a.article:hover {
    background: #6d7fcc !important;
    color: #fff !important;
}


/* #content {
    padding-left: 20px;
    min-height: 100vh;
    transition: all 0.3s;
    width: stretch;
} */

@media (max-width: 768px) {
    #sidebar {
        min-width: 80px;
        max-width: 80px;
        text-align: center;
        margin-left: -80px !important;
    }
    a[aria-expanded="false"]::before,
    a[aria-expanded="true"]::before {
        top: auto;
        bottom: 5px;
        right: 50%;
        -webkit-transform: translateX(50%);
        -ms-transform: translateX(50%);
        transform: translateX(50%);
    }
    #sidebar.active {
        margin-left: 0 !important;
    }
    nav#sidebar li#logout_sidebar_button {
        min-width: 0px;
        max-width: 0px;
    }
    #sidebar .sidebar-header h3,
    #sidebar .CTAs {
        display: none;
    }
    #sidebar .sidebar-header img.hidden-small,
    #sidebar .CTAs {
        display: none;
    }
    #sidebar .sidebar-header strong {
        display: block;
    }
    #sidebar ul li a {
        padding: 20px 10px;
    }
    #sidebar ul li a span {
        font-size: 0.85em;
    }
    #sidebar ul li a i {
        margin-right: 0;
        display: block;
    }
    #sidebar ul ul a {
        padding: 10px !important;
    }
    #sidebar ul li a i {
        font-size: 1.3em;
    }
    #sidebar {
        margin-left: 0;
    }
    #sidebarCollapse span {
        display: none;
    }
    #content {
        padding-left: 0px;
    }
    .navbar {
        margin-left: 0px;
    }
}

.btn {
    font-weight: 700;
    height: 36px;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    cursor: default;
}

.reauth-email {
    display: block;
    color: #404040;
    line-height: 2;
    margin-bottom: 10px;
    font-size: 14px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.form-signin input {
    direction: ltr;
    height: 75px;
    font-size: 18px;
    border-radius: 10px 10px;
    border: 2px solid #4A90E2;
}

.form-signin input,
.form-signin button {
    width: 100%;
    display: block;
    margin-top: 30px;
    margin-bottom: 10px;
    z-index: 1;
    position: relative;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.form-signin .form-control:focus {
    border-color: #4A90E2;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgb(104, 145, 162);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgb(104, 145, 162);
}

.btn.btn-signin {
    background-color: #3DD768;
    padding: 0px;
    font-weight: 700;
    font-size: 18px;
    height: 66px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    border: none;
    -o-transition: all 0.218s;
    -moz-transition: all 0.218s;
    -webkit-transition: all 0.218s;
    transition: all 0.218s;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}

.btn.btn-signin:hover,
.btn.btn-signin:active,
.btn.btn-signin:focus {
    background-color: rgb(40, 183, 78);
}

.btn.btn-signin:disabled {
    background-color: rgb(150, 224, 170);
}

.forgot-password {
    color: rgb(104, 145, 162);
}

.forgot-password:hover,
.forgot-password:active,
.forgot-password:focus {
    color: rgb(40, 183, 78);
}

.text-center {
    text-align: center;
}

.text-error {
    color: #C12426;
}

.form-signup input {
    direction: ltr;
    height: 75px;
    font-size: 18px;
    border-radius: 10px 10px;
    border: 2px solid #4A90E2;
}

.form-signup input,
.form-signup button {
    width: 100%;
    display: block;
    margin-bottom: 10px;
    z-index: 1;
    position: relative;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.form-signup .form-control:focus {
    border-color: #4A90E2;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgb(104, 145, 162);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgb(104, 145, 162);
}

.btn.btn-signup {
    background-color: #3DD768;
    font-family: 'Gotham Rounded', sans-serif;
    margin-top: 30px;
    font-size: 20px;
    padding: 25px;
    font-weight: 700;
    height: 70px;
    -moz-border-radius: 10px 10px;
    -webkit-border-radius: 10px 10px;
    border-radius: 10px 10px;
    border: none;
    -o-transition: all 0.218s;
    -moz-transition: all 0.218s;
    -webkit-transition: all 0.218s;
    transition: all 0.218s;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}

.btn.btn-signup:hover,
.btn.btn-signup:active,
.btn.btn-signup:focus {
    background-color: rgb(40, 183, 78);
}

.btn.btn-signup:disabled {
    background-color: rgb(150, 224, 170);
}

.error-template {
    padding: 40px 15px;
    text-align: center;
}

.error-actions {
    margin-top: 15px;
    margin-bottom: 15px;
}

.error-actions .btn {
    margin-right: 10px;
}

.form-forgotPassword #inputEmail {
    direction: ltr;
    height: 75px;
    font-size: 18px;
    border-radius: 10px 10px;
    border: 2px solid #4A90E2;
}

.form-forgotPassword input[type=email],
.form-forgotPassword button {
    width: 100%;
    display: block;
    margin-bottom: 10px;
    z-index: 1;
    position: relative;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.form-forgotPassword .form-control:focus {
    border-color: #4A90E2;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgb(104, 145, 162);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgb(104, 145, 162);
}

.btn.btn-forgotPassword {
    background-color: #3DD768;
    padding: 0px;
    font-weight: 700;
    font-size: 18px;
    height: 66px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    border: none;
    -o-transition: all 0.218s;
    -moz-transition: all 0.218s;
    -webkit-transition: all 0.218s;
    transition: all 0.218s;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}

.btn.btn-forgotPassword:hover,
.btn.btn-forgotPassword:active,
.btn.btn-forgotPassword:focus {
    background-color: rgb(40, 183, 78);
}

.btn.btn-forgotPassword:disabled {
    background-color: rgb(150, 224, 170);
}

.text-white {
    color: #fff !important;
}


/* Text-transform from Bootstrap 5 */

.text-lowercase {
    text-transform: lowercase !important
}

.text-uppercase {
    text-transform: uppercase !important
}

.text-capitalize {
    text-transform: capitalize !important
}


/* Background from Bootstrap 4 */

.bg-primary {
    background-color: #007bff !important
}

a.bg-primary:focus,
a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
    background-color: #0062cc !important
}

.bg-secondary {
    background-color: #868e96 !important
}

a.bg-secondary:focus,
a.bg-secondary:hover,
button.bg-secondary:focus,
button.bg-secondary:hover {
    background-color: #6c757d !important
}

.bg-success {
    background-color: #3DD768 !important
}

a.bg-success:focus,
a.bg-success:hover,
button.bg-success:focus,
button.bg-success:hover {
    background-color: #1e7e34 !important
}

.bg-success-secondary {
    background-color: #3DD768 !important;
}

a.bg-success-secondary:focus,
a.bg-success-secondary:hover,
button.bg-success-secondary:focus,
button.bg-success-secondary:hover {
    background-color: #1e7e34 !important
}

.bg-info {
    background-color: #3FBBF8 !important
}

a.bg-info:focus,
a.bg-info:hover,
button.bg-info:focus,
button.bg-info:hover {
    background-color: #117a8b !important
}

.bg-warning {
    background-color: #FFC42C !important
}

a.bg-warning:focus,
a.bg-warning:hover,
button.bg-warning:focus,
button.bg-warning:hover {
    background-color: #d39e00 !important
}

.bg-danger {
    background-color: #F95455 !important
}

a.bg-danger:focus,
a.bg-danger:hover,
button.bg-danger:focus,
button.bg-danger:hover {
    background-color: #bd2130 !important
}

.bg-light {
    background-color: #f8f9fa !important
}

a.bg-light:focus,
a.bg-light:hover,
button.bg-light:focus,
button.bg-light:hover {
    background-color: #dae0e5 !important
}

.bg-dark {
    background-color: #343a40 !important
}

a.bg-dark:focus,
a.bg-dark:hover,
button.bg-dark:focus,
button.bg-dark:hover {
    background-color: #1d2124 !important
}

.bg-cyan {
    background-color: #D63B6F !important
}

a.bg-cyan:focus,
a.bg-cyan:hover,
button.bg-cyan:focus,
button.bg-cyan:hover {
    background-color: #8a2245 !important
}

.bg-blue {
    color: #fff;
    background-color: #010c28;
}

a.bg-blue:focus,
a.bg-blue:hover,
button.bg-blue:focus,
button.bg-blue:hover {
    color: #fff !important;
    background-color: #2e6c86 !important
}

.bg-blue-secondary {
    color: #fff;
    background-color: #0275AF;
}

a.bg-blue-secondary:focus,
a.bg-blue-secondary:hover,
button.bg-blue-secondary:focus,
button.bg-blue-secondary:hover {
    background-color: #02405f !important;
    color: #fff !important;
}

.bg-white {
    background-color: #fff !important
}

.bg-transparent {
    background-color: transparent !important
}

.text-success-secondary {
    color: #3DD768;
}

.text-danger {
    color: #F95455;
}

.btn-transparent {
    border-color: transparent !important;
}

.box-shadow {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 1px 1px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
}

.minimal-box-shadow {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
}

.box-shadow:hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.table td,
.table th {
    border: 0;
}

.table thead>tr>th {
    vertical-align: text-top;
    border-bottom: none;
    box-shadow: none;
    text-shadow: none;
}

.p-5-top {
    padding-top: 5%;
}

.p-5 {
    padding: 5px;
}

.p-1-bottom {
    padding-bottom: 1%;
}

.p-3-bottom {
    padding-bottom: 3%;
}

.p-3-top {
    padding-top: 3%;
}

.p-3-right {
    padding-right: 3%;
}

.p-10-top {
    padding-top: 10%;
}

.p-10-bottom {
    padding-bottom: 10%;
}

.p-16-bottom {
    padding-bottom: 16%;
}

.p-5-bottom {
    padding-bottom: 5%;
}

.p-1-bottom {
    padding-bottom: 1%;
}

.font-size-20 {
    font-size: 20px !important;
}

.input-big {
    direction: ltr;
    height: 50px;
    font-size: 16px;
    border: 1px solid #4AA3E2;
    width: 100%;
    display: block;
    margin-bottom: 10px;
    z-index: 1;
    position: relative;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-family: 'Gotham Rounded', sans-serif, 'FontAwesome';
}

.input-bordered {
    direction: ltr;
    border: 1px solid #4AA3E2;
    font-family: 'Gotham Rounded', sans-serif;
    border-radius: 2px;
}

.input-bordered:disabled {
    border: 1px solid #90C0F8;
}

.btn-space {
    margin-right: 3%;
}

.btn-big {
    padding: 0px;
    font-weight: 700;
    font-size: 18px;
    height: 66px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    border: none;
    -o-transition: all 0.218s;
    -moz-transition: all 0.218s;
    -webkit-transition: all 0.218s;
    transition: all 0.218s;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}

@media (min-width: 768px) {
    .btn-large {
        height: 50px;
        width: 25%;
    }
}

#footer {
    padding-top: 5%;
}

.popup-close {
    width: 34px;
    height: 34px;
    padding-top: 4px;
    display: inline-block;
    position: absolute;
    top: 20px;
    right: 20px;
    -webkit-transform: translate(50%, -50%);
    transform: translate(50%, -50%);
    border-radius: 100%;
    background: transparent;
    border: solid 4px #808080;
}

.popup-close:after,
.popup-close:before {
    content: "";
    position: absolute;
    top: 11px;
    left: 5px;
    height: 4px;
    width: 16px;
    border-radius: 30px;
    background: #808080;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.popup-close:after {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.popup-close:hover {
    -webkit-transform: translate(50%, -50%) rotate(180deg);
    transform: translate(50%, -50%) rotate(180deg);
    background: #f00;
    text-decoration: none;
    border-color: #f00;
}

.popup-close:hover:after,
.popup-close:hover:before {
    background: #fff;
}

.fa-1x {
    vertical-align: middle;
}

.fa-2x {
    vertical-align: middle;
}

.fa-3x {
    vertical-align: middle;
}

.fa-4x {
    vertical-align: middle;
}

.fa-5x {
    vertical-align: middle;
}

.fa-padding-right {
    padding-right: 15px;
}

.fa-padding {
    padding: 0px 3%;
}

.modal-header {
    border-bottom: none;
}

.modal-content {
    border: 1px solid #ffffff;
}

.modal-footer {
    border-top: none;
}

[hidden] {
    display: none !important;
}

textarea {
    resize: vertical;
}

#logout_sidebar_button {
    position: absolute;
    bottom: 0;
    width: 250px;
}

#sidebar.active ul li#logout_sidebar_button a {
    width: 90px;
}

.borderless td,
.borderless th {
    border: none !important;
}

#iconified,
.iconified,
.react-bs-table-search-form {
    font-family: 'FontAwesome', 'Gotham Rounded', sans-serif !important;
}

button i.fa {
    padding-right: 2%;
}

.fa-padding-right-2 {
    margin-right: 2%;
}

.arrow-toggle {
    transition: all 1s ease-in-out;
}

.arrow-toggle:after {
    font-family: 'Glyphicons Halflings';
    content: "\e114";
    float: right;
    color: gray;
}

.arrow-toggle.collapsed:after {
    content: "\e080";
}

.clickable {
    cursor: pointer;
}

.results tr[visible='false'],
.no-result {
    display: none;
}

.results tr[visible='true'] {
    display: table-row;
}

.react-datepicker {
    font-size: 1em;
}

.react-datepicker__header {
    padding-top: 0.8em;
    text-transform: capitalize;
}

.react-datepicker__month {
    margin: 0.4em 1em;
}

.react-datepicker__day-name,
.react-datepicker__day {
    width: 1.9em;
    line-height: 1.9em;
    margin: 0.166em;
}

.react-datepicker__current-month {
    font-size: 1em;
}

.react-datepicker__navigation {
    top: 1em;
    line-height: 1.7em;
    border: 0.45em solid transparent;
}

.react-datepicker__navigation--previous {
    border-right-color: #ccc;
    left: 1em;
}

.react-datepicker__navigation--next {
    border-left-color: #ccc;
    right: 1em;
}

.react-datepicker option {
    text-transform: capitalize;
}

.react-datepicker__month-select {
    text-transform: capitalize;
}

.react-datepicker__input-container {
    display: block;
}

h4.collapsed {
    color: #777;
}

.table-responsive {
    border: none !important;
}

.text-info {
    color: #5bc0de;
}

.text-info:hover {
    color: #31b0d5;
}

.big-modal {
    margin-left: 10% !important;
    margin-right: 10% !important;
    width: 80% !important;
}

.shopImageSize {
    height: 200px;
}

.floatCostRight {
    float: right;
}

.title-color-card {
    color: rgb(5, 37, 95);
    height: 16px !important;
}

.card-class {
    max-width: 245px !important;
    margin-right: 10px;
}

.float-visual {
    float: right;
    margin-right: 6px;
    font-size: 9px;
    color: #434343;
}

.color-class {
    color: #434343;
}

.centralized-divs {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
}

.back-div {
    height: 158px;
    width: 258px;
    -webkit-border-top-left-radius: 27px;
    -webkit-border-top-right-radius: 27px;
    -moz-border-radius-topleft: 27px;
    -moz-border-radius-topright: 27px;
    border-top-left-radius: 27px;
    border-top-right-radius: 27px;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    background-repeat: no-repeat;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    margin-top: -1px;
}

.photo-class {
    height: 390px;
    width: 647px;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    background-repeat: no-repeat;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.div-radius {
    margin: auto;
    margin-right: 10px;
    margin-top: 10px !important;
    max-width: 260px;
    border: 1px solid rgb(230, 222, 222);
    -webkit-border-radius: 28px;
    -webkit-border-bottom-right-radius: 10px;
    -webkit-border-bottom-left-radius: 10px;
    -moz-border-radius: 28px;
    -moz-border-radius-bottomright: 10px;
    -moz-border-radius-bottomleft: 10px;
    border-radius: 28px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    -webkit-box-shadow: 0px 1px 15px 0px rgba(143, 130, 143, 1);
    -moz-box-shadow: 0px 1px 15px 0px rgba(143, 130, 143, 1);
    box-shadow: 0px 1px 15px 0px rgba(143, 130, 143, 1);
    padding-left: 0;
    padding-right: 0;
    height: 410px;
}

.select-style {
    height: 40px;
    border-radius: 7px;
    border-color: #007bff;
}

.rccs__card--unknown>div {
    background: linear-gradient(25deg, #07125f, #5250ce) !important;
    margin-left: 118px;
}

@media (max-width: 768px) {
    .card-response {
        margin-left: 0px;
    }
}

.card-div-conteudo {
    padding-left: 10px;
    padding-right: 10px;
}

.hr-style {
    border-color: darkgray;
    width: 240px;
    margin-bottom: 4px;
}

.price {
    font-size: 22px;
    margin-bottom: 14px;
    color: #434343;
}

.price-in-details {
    font-size: 40px;
    margin-bottom: 14px;
    color: #434343;
}

.float-text {
    margin-right: -7px;
    display: flex;
    align-items: flex-end;
    float: right;
    color: #434343;
}

.float-text-left {
    font-family: "Gotham Rounded medium";
    display: flex;
    align-items: flex-end;
    float: left;
    color: #434343;
}

.description-title {
    font-family: "Gotham Rounded medium";
    margin-top: 30px;
    font-size: 20px;
}

.card-margin {
    margin-top: 20px;
    margin-bottom: 30px;
}

.btn-purchase {
    background-color: #3DD768;
    color: white;
    font-family: "Gotham Rounded light";
    font-size: 20px;
    padding: 5px;
    width: 280px;
    height: 50px;
    border-radius: 5px;
}

.description-text {
    margin-top: 30px;
    font-family: "Gotham Rounded light";
    color: #505050;
}

.intructor-text {
    font-family: "Gotham Rounded medium";
    color: #505050;
    font-size: 22px;
}

.float-text-left-buttom {
    font-family: "Gotham Rounded medium";
    display: flex;
    align-items: flex-end;
    float: left;
    color: #80ff80;
}

.buttom-style {
    margin-left: 15px;
    border-radius: 7px;
    width: 180px;
    height: 54px;
    background-color: #3DD768;
    color: whitesmoke;
    border: none;
    font-size: 18px;
}

.buttom-style-add-car {
    border-radius: 7px;
    width: 300px;
    height: 54px;
    color: gray;
    background-color: transparent;
    font-size: 18px;
    border: none;
}

.margin-duration {
    margin-right: 170px;
}

.temp-duration {
    color: #4d4d4d;
}

.input-placeholder-profile {
    font-size: 13px;
    border: 1px solid darkgray;
}

.color-span-card {
    color: #17549b;
    font-size: 46px;
    margin-bottom: 30px;
}

.search-div {
    margin-bottom: 40px;
    margin-left: 40px;
}

.input-card-height {
    height: 75px !important;
}

.font-text-card {
    margin-top: 6px;
    font-size: 13px;
    color: black;
}

.hr-shop-theme {
    width: 81%;
    border-top: 1px solid darkgray;
}

.div_icon {
    background-image: url(../src/deprecated/components/Images/background.jpg);
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    background-repeat: no-repeat;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.div-instructor {
    height: 100px;
    width: 100px;
    border-radius: 100%;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    background-repeat: no-repeat;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    border: 1px solid darkgray;
    margin-top: 20px;
}

.name-instructor {
    margin-left: 110px;
    margin-top: -100px;
    font-family: "Gotham Rounded medium";
    color: #505050;
    font-size: 16px;
}

.input-shop {
    border-top: none;
    border-right: none;
    border-bottom: 1px solid darkgray;
    border-left: none;
    margin-left: 10px;
    width: 280px;
    background-color: #F7F9FA;
}

.row-cards {
    padding-left: 50px !important;
}

.class-list {
    font-family: "Gotham Rounded medium";
    margin-top: 1%;
    border: 1px solid darkgray;
    background-color: white;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 2vh;
}

.all {
    width: 90%;
    margin-left: 10%
}

.all2 {
    position: relative;
    width: 50%;
    float: left;
}

.all3 {
    position: relative;
    float: left;
}

.all4 {
    position: relative;
    width: 100%;
    float: left;
}

.text-prof {
    margin-top: 55px;
    width: 380px;
    font-family: "Gotham Rounded light";
    color: #505050;
}

.div-stars {
    float: right;
}

.purschase-text {
    color: darkgray;
    font-size: 20px;
}

.card-form {
    height: 50px;
    border-radius: 18px;
}

.card-text-theme-name {
    color: black;
    font-size: 20px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.card-price-text {
    font-family: "Gotham Rounded light";
    color: rgb(66, 155, 238);
    font-size: 28px;
}

.pagamento {
    color: #17549B;
    font-size: 46px;
    font-family: 'Gotham Rounded medium';
}

@media (max-width:985px) and (min-width:771px) {
    .row-cards {
        margin-left: 10% !important;
        padding-left: 0px !important;
    }
}

@media (max-width:770px) {
    .div-radius {
        margin-left: auto;
        margin-right: auto;
    }
}

@media(max-width:1310px) {
    .photo-class {
        height: 323px;
        width: 535px;
    }
}

@media(max-width:1088px) {
    .photo-class {
        height: 234px;
        width: 386px;
    }
    .stars:nth-child() {
        font-size: 55px;
    }
}

@media (max-width:800px) {
    .div-stars {
        width: 75vw;
        float: right;
    }
    .photo-class {
        height: 50vw;
        width: 83vw;
        background-repeat: no-repeat;
        -webkit-background-size: cover;
        background-repeat: no-repeat;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }
    .text-prof {
        margin-top: 55px;
        width: 100%;
        font-family: "Gotham Rounded light";
        color: #505050;
    }
}

@media(max-width:375px) {
    .card-addtional-lessons {
        height: 182px !important;
        background-size: contain !important;
    }
}

@media(max-width:320px) {
    .card-addtional-lessons {
        height: 163px !important;
        background-size: contain !important;
    }
}

.back {
    display: flex;
    margin-left: 50px;
}

.back-font {
    font-size: 3vh;
    color: black;
    text-decoration: underline !important;
}

.products-font {
    font-family: "Gotham Rounded medium";
    height: 100%;
    width: 100%;
    margin: auto;
    font-size: 30px;
}

.cart-font {
    font-family: "Gotham Rounded medium";
    height: 100%;
    margin: auto;
    font-size: 30px;
}

.cart-back {
    color: black;
    font-size: 20pt;
    font-family: Gotham Rounded Book;
}

.themes-font {
    font-family: "Arial";
    height: 100%;
    width: 100%;
    margin: auto;
    font-size: 19px;
}

.padding-lesson {
    padding-left: 3vh;
    margin-top: 1vh;
}

.font-lessons {
    font-family: "Gotham Rounded light";
    color: #000;
}

.panel-body {
    padding-top: 0px !important;
}

.text-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 20px;
    background: rgba(0, 0, 0, 0.16);
    background-image: linear-gradient(180deg, #50d8be90 0%, #50d8be 70%);
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.2;
    opacity: 0;
    transition: opacity .3s ease-in-out;
}

.text-container:hover {
    opacity: 1;
}

.grid-collapse__card {
    display: flex;
    width: 223px;
    height: 90px;
    margin: 10px 20px;
    border-radius: 10px;
    background-color: #ffffff;
    cursor: pointer;
    overflow: hidden;
}

.excluir {
    float: right;
    color: red;
    font-size: 24px;
    margin-top: -11px;
    margin-right: -13px;
}

@media (max-width: 768px) {
    .rccs__card--front {
        margin-left: 0px !important;
    }
    .purchase-margin-response {
        margin-left: 4vh;
    }
    .purchase-margin {
        margin-left: 0%;
    }
}

.top-purchase {
    margin-top: 1vh;
}

@media (min-width: 769px) {
    .col-lg-offset-3 {
        margin-left: 20% !important;
    }
    .pad-pag {
        margin-right: 14vh;
    }
    .purchase-margin {
        margin-left: 32%;
    }
}

.logo {
    width: 65px;
    margin: 15px;
}

#containerlogos {
    display: flex;
    justify-content: center;
    align-items: center;
}
